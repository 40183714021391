body {
  margin: 0;
  /* font-family: "Bebas Neue", sans-serif; */
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:white;
  background-color: #373636;
  min-height: 100vh;
  width: 100%;
  /* overflow: hidden; */

  position: relative;
  /* height: 100vh; */
}

h1,h2,h3{
  font-family: "Bebas Neue", sans-serif;
  letter-spacing: 1px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
