.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.grid-5{
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(5,1fr);
}
.media-grid-item{
  display: flex
  ;
  margin: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
}
.flex-center{
  display: flex;
  justify-content: center;
}
.flex-column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flex-column-end {
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: column;
}
.flex-column-end {
  display: flex;
  /* align-items: center; */
  justify-content: end;
  flex-direction: column;
}
.wrap{
    flex-wrap: wrap;
}
.flex-column-str {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    gap: 3rem;
    flex-direction: column;
  }
.flex-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.m-auto{
    margin: auto;
}
.m-l-3{
    margin-left: 1rem;
}
.m-t-3{
    margin-top: 1rem;
} 
.m-t-5{
  margin-top: 5rem;
}
.m-b-5{
  margin-bottom: 5rem;
}
.m-b-3{
    margin-bottom: 1rem;
}
.p-t-1{
    padding-top: .5rem;
}
.p-b-1{
    padding-bottom: .5rem;
}
.p-t-3{
    padding-top: 1.5rem;
}
.p-r-3{
  padding-right: 1.5rem;
}
.p-l-3{
  padding-left: 1.5rem;
}
.p-b-3{
    padding-bottom: 1.5rem;
}
.center{
    text-align: center;
}
.justify{
  text-align: justify;
}
.left{
  text-align: left;
}
.inpt-box{
  background: #707B8D;

  color: white;
  /* border-radius: .5rem; */
  margin-top: .4rem;
  border: none;
  padding-left: .5rem;
  height: 3rem;
  width: 98%;

}
.inpt-box::placeholder{
color: white;
}
/* .inpt-box:focus{
  border:rgba(245, 52, 87, 1) 35%, 1px solid;
  border: black solid 1px;
  outline: none;
} */
.inpt-box:-internal-autofill-selected {
  background: #707B8D;
  
}

.flex-gap {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  /* justify-content: flex-between; */
}
.head-md {
  font-size: 1.5rem;
  font-family: "Bebas Neue", sans-serif;
  letter-spacing: 1px;
  font-weight: 600;
}
.inter{
  font-family: Inter !important;
}
.roboto-condensed{
  font-family: "Roboto Condensed", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}
.head-sm {
    font-size: 1.1rem;
  font-family: "Bebas Neue", sans-serif;
  letter-spacing: 1px;
    font-weight: 500;
  }
  .head-xsm {
    font-size: .8rem;
    font-weight:400;
  }
.head-lg {
  font-size: 2.6rem;
  font-family: "Bebas Neue", sans-serif;
  letter-spacing: 1px;

  font-weight: 600;
}
.head-xlg {
  font-size: 2.6rem;
  font-family: "Bebas Neue", sans-serif;
  letter-spacing: 1px;
  font-weight: 700;
}
.head-xxlg {
  font-size: 4.2rem;
  font-weight: 800;
}
.font-w-4{
    font-weight: 400;
}

.primary-color {
  color: white;
}
.primary-dark-color {
  color: #06197B;
}
.secondary-color{
  color:#FF7031;
}
.secondary-light-color{
  color:#6D6D6D;
}
.gradiant-button {
  background: rgb(255, 114, 32);
  background: linear-gradient(
    90deg,
    rgba(255, 114, 32, 1) 0%,
    rgba(245, 52, 87, 1) 35%,
    rgba(190, 16, 179, 1) 100%
  );
}
.orange-background{
  background-color: #FF6521;
  padding: .5rem 1rem;
  max-width: fit-content;
 
}
.orange-background{
  background-color: #FF6521;
  padding: .5rem 1rem;
  max-width: fit-content;
 
}
.fill-background{
  background-color: #FF6521;
  padding: .5rem 1rem;
  max-width: fit-content;
  text-align: center;
}
.blank-background{
  /* background-color: #FF6521; */
  background-color: transparent;
  padding: .5rem 1rem;
text-align: center;
  max-width: fit-content;
 
}
.gradiant-blue-col {
  background: linear-gradient(234.54deg, #354C8C -6.38%, #8884AD 74.78%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradiant-light-blue{
  background: linear-gradient(94.19deg, #524B7D 1.06%, #2A498C 114.35%);


  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}
.gradiant-blue {
  background: radial-gradient(50% 50% at 50% 48%, #0a1f85 0%, #060d3d 100%);
}
.feedback-container {
  position: relative;
  display: inline-block;
  padding:1rem 2rem ;
  background: linear-gradient(270deg, #010B3E 0%, #271B3B 100%);
   font-size: .7rem;
   font-weight: 400;
  color: #FFFFFF;
  /* font-size: 1.5rem; */
  /* background: #000; */
  z-index: 1;
}
.white-background{
  color:#4F4E4E ;
  padding: .9rem;
  max-width: fit-content;
  background-color: white;
}
.gray-background{
  background-color: #5B5B5B;
  padding: 1rem;
  border-radius: .2rem;
}
.feedback-container-border {
  content: "";
  position: absolute;
  /* top: -3px; */
  /* background: rgb(255, 114, 32); */
  top: 0;
  left: -3px;
  /* right: -3px;
  bottom: -3px; */
  background: rgb(253,104,41);
  background: linear-gradient(185deg, rgba(253,104,41,1) 24%, rgba(244,52,88,1) 58%, rgba(200,22,163,1) 82%);
  z-index: -1;
  width: 5px;
  height: 100%;
  border-radius: 10px; /* Optional for rounded corners */
}
.whiteline{
  position: absolute;
  top: -220px;

 
}
.whiteline-bottom{
  position: absolute;
  bottom: -505px;

 
}
.req-button {
  border-radius: 1.2rem;
  padding: 0.7rem 1.3rem;
  border: none;
  font-weight: 600;
}
.white-back-button{
  border-radius: .5rem;
  padding: 0.7rem 2rem;
  /* border: 2px solid white; */
  /* display: flex; */
  background-color: white;
  width: fit-content;
  align-items: center;
  gap: .8rem;
  color:#2C2C2C ;
  cursor: pointer;
  font-weight: 600;
}
.white-button{
  border-radius: 1.5rem;
  padding: 0.7rem 2rem;
  border: 2px solid white;
  /* display: flex; */
  align-items: center;
  /* font-size: ; */
  gap: .8rem;
  color:#2C2C2C ;
  cursor: pointer;
  font-weight: 600;
}
.outline-button{
  border-radius: .5rem;
  padding: 0.7rem 2rem;
  border: 2px solid white;
  display: flex;
  align-items: center;
  gap: .8rem;
  background-color: transparent;
  color: white;
  cursor: pointer;
  font-weight: 600;
}
.submit-button {
  /* border-radius: 1.5rem; */
  padding: 0rem 5rem;
  border: none;
  color: white;
  cursor: pointer;

  font-size: 1rem;
  font-weight: 600;
}
.form-container{
  /* border:2px white solid ; */
  background-color: #242424a6;
  padding:2rem;
  border-radius: .5rem;
}
.boldHrWhite{
  min-height: 20px;
  background-color:#E8E8E8;
}
.w-60 {
  width: 60%;
}
.w-80 {
  width: 80%;
}
.w-70 {
  width: 70%;
}
.w-35 {
  width: 35%;
}
.w-30 {
  width: 30%;
}
.w-40 {
    width: 40%;
  }
.w-90 {
  width: 90%;
}
.w-95 {
  width: 95%;
}
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
w-fit{
  min-width: fit-content;
}
.border-rds-1{
  border-radius: 1rem;
}
.navbar {
  padding: 0.5rem 1rem;
  position: sticky;
  background: radial-gradient(50% 50% at 50% 50%, #FF8753 0%, #F65914 100%);

  z-index: 10;
  top: 0;
}
.tab:hover{
  color: #ffffff;
  text-decoration: underline 3px;
  text-underline-offset: 8px;


}
.overlay{
  background-color:rgb(63 63 63 / 54%);
}
.active-tab{
  color: #ffffff;
  text-decoration: underline 3px;
  text-underline-offset: 8px;
  
}
.footer{
  padding: .2rem;
  position: absolute;
  bottom: 0;
  width: -webkit-fill-available;
  color: white;
  margin-top: 0.5rem;
  background-color: #666666;

}

.pointer {
  cursor: pointer;
}
.cover {
  position: relative;
}
.cover-image {
  width: -webkit-fill-available;
  position:absolute;
  background: #38383870;
  top: 0%;
}
.backgroundImage{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.cover-content-title{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  /* bottom: 0; */
 top: 0;
  /* left: 0; */
  width: 100%;
  margin-top: 6rem;
  gap: 2rem;
}
.cover-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-top: 6rem;
  gap: 2rem;
}
.brand-list {
  background: #08080842;
  width: 100%;
  padding: .5rem 0em;
  gap: 20px;
  justify-content: center;
}
.language-card{
    border-radius: 1.2rem;
    background-color: #F6F6F6;
    color:#060606 ;
    /* position: relative; */
    min-height: 350px;
    max-height: 379px;


}
.language-container{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 2rem;
}
.categories-container{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 2rem;
}
.categories-container2{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 2rem;
  width: 80%;
}

.workWith{
  display: grid;
    grid-template-columns: repeat(5,1fr);
    gap: 1rem;
}

.language-content{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.language-card img:first-child{
    border-top-left-radius: 1.2rem;
    
    width: 100%;
    border-top-right-radius: 1.2rem;
}
.language-tab{
    position: absolute;
    bottom: 10%;
    color: white;
    font-size: .8rem;
    font-weight: 500;
    padding: .5rem 2rem;
    left: 0%;
    background-color:#4762A2 ;
}
.language-title{
    color: #2F4A8A;
    font-size: .9rem;
    font-weight: 500;
}
.language-spcl{
    background-color: #C5D6FF;
    border-radius: 1rem;
    font-size: .8rem;
    padding: .2rem .5rem;
}
.language-action{
    background-color: #ECECEC;
    padding: .5rem;
    font-size: .9rem;
    margin: 0rem .5rem;

    display: flex;
    gap: 15px;
}
.language-req-button{

    border-radius: .3rem;
    padding: .5rem 1rem;
    background-color: #4762A2;
    color: white;
    border: none;
    margin: auto;
    
}
.info-banner{
    position: relative;
    background-color: #863bbfa1;
    border-radius: 1.2rem;
    height: 100%;

}
.video-play{
  position: absolute;
  bottom: 50%;
}
.video-banner{
    position: relative;
    background-color: #273bcf9d;
    border-radius: 1.2rem;
    height: 100%;
}
.white-banner{
  background-color: white;
  color: #212121;
  text-align: center;
  letter-spacing: 1px;
  padding: 2.5rem .5rem;
}
.blue-banner{
  background-color: #010B3E;
  /* color: #212121; */
  text-align: center;
  background-position: center;
  background-size: cover;
  letter-spacing: 1px;

  padding: 3.5rem .5rem;
  /* background: linear-gradient(190.7deg, #1E1F58 -48.27%, #000C47 79.24%); */

}
.feature-container{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 2rem;
}
.feature-card{
  border-radius: 1.2rem;
  position: relative;
  background-color: #F6F6F6;
  display: flex;
  flex-direction: column;
  justify-content: end;
  color:#060606 ;
  height: 24rem;
  /* position: relative; */
  padding: .5rem;
  /* min-height: 350px;
  max-height: 379px; */
}
.feature-cover{
  position: absolute;
  /* top: 10%; */

}
.feature-content{
  /* position:absolute; */
  /* bottom: 20%; */
  padding: 1rem 0rem;


}
.secure-grid{
  display: grid
  ;
      width: auto;
      margin: 0rem 4rem;
      grid-template-columns: repeat(2, 1fr);
      gap: 28px;
}
.secure-img{
  width: 121px;
  position: relative;
}
.defenceflex-img{
  width: 30%;
}
.secure-title{
  position: absolute;
  top: 40%;
  left: 25%;
  font-size: 1.3rem;
  font-weight: 500;

}
.outcome-banner{
  background-color: #010B3E;
  /* color: #212121; */
  text-align: left;
  letter-spacing: 1px;
  color: white;
  border-radius: 1rem;

  padding: 3.5rem;
  /* background: linear-gradient(190.7deg, #1E1F58 -48.27%, #000C47 79.24%); */
}
.about-cover{
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.value.cover{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.value-circle{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex
;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  border: 4px solid white;
}
.news-img{
  border-radius: 2.3rem;
  width: 100%;
}
.cornerDiv{
 background-color: #FF7031;
 min-height: 200px;
 width: 200px;
 z-index: -10;
 position: absolute;
 top: -5%;
 right: -4%;
}
.cornerDivLeft{
  background-color: #FF7031;
  /* min-height: 200px; */
  width: 200px;
  z-index: -10;
  position: absolute;
  top: -5%;
  left: -4%;
 }
.flex-img{
  border-radius: 1.3rem;
  width: 100%;
}
.performance-img{
  border-radius: 2.3rem;
  width: 600px;
}
.join-button{
  border-radius: 2rem;
  border: none;
  padding: 1rem 5rem;
  font-size: 1rem;
  font-weight: 600;
  background-color: white;
  

}
.value-img{
  position: absolute;
  width:-webkit-fill-available;
  bottom: -30%;
}
.line-heading-cont{
  display: flex;
  justify-content: space-between;
  gap: 10px;
  
}
.line-heading{
   border-top: 3px solid #FF6521;
  width: 30%;


  
}
.line-mid-heading{
  margin-top: -20px;
  min-width: max-content;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  width: max-content;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  font-family: "Bebas Neue", sans-serif;

  z-index: 1;
}
.dropdown-item{
  color:#373636;
  font-size: .9rem;
  padding: .5rem;
  cursor: pointer;

}
.dropdown-item-active{
  color:#FF6521;
  font-size: .9rem;
  padding: .5rem;
  cursor: pointer;


}
.dropdown-item:hover{
background-color: gainsboro;  
/* padding: .6rem; */
}
.dropdown-item-active:hover{
  background-color: gainsboro;  
  /* padding: .6rem; */
  }

.dropdown:hover .dropdown-content {
  display: block;
}

.tab-container{
  padding: 1rem;
  border: 2px solid white;

  border-radius: .2rem;
}
.learn-more{
  position: absolute;
  bottom: -10%;
  margin-top: 2rem;
}
.orange-hr{
  position: absolute;
 color: #FF7031;
  border-style: solid;
}
.comment-cover{
  position: relative;
  width: 60%;
  text-align: justify;
  margin: auto;
  letter-spacing: 1px;
  line-height: 1.5;
  font-size: 1.1rem;

}
.comment-left{
  position: absolute;
  top: -11px;
  width: 22px;
  left: -28px;

}
.comment-right{
  position: relative;
  /* position: relative;
  bottom: -16px;
  right: -24px; */
  display: inline-block;

  width: 20px;
  margin-bottom: 5px;

}











.menu{
  color:#4F4F4F ;
  /* margin-left: 1rem; */
  padding: 1rem 1rem 1rem 2rem;

  }
  .mainMenu{
    color:#4F4F4F ;
    
  /* margin-left: 1rem; */
  padding: 1rem 1rem 1rem 2rem;
  }
  .menuActive{
    background: radial-gradient(50% 50% at 50% 50%, #FF8753 0%, #F65914 100%);;
    color: white;
  /* margin-left: 1rem; */
  
    padding: 1rem 1rem 1rem 2rem;
  }
  .menu:hover{
    background: radial-gradient(50% 50% at 50% 50%, #FF8753 0%, #F65914 100%);;
    color: white;
  }
  .menus{
    border-radius: 1rem 0rem 0rem 1rem;
    height: 80vh !important;
    top: 7% !important;
    font-family: "Bebas Neue", sans-serif;
  
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /* --------------------tablet view ---------------------- */
  @media only screen and (max-width:700px) {
    .language-container{
      /* grid-template-columns: repeat(2,1fr); */
      display: flex;
      /* flex-direction: column; */
      align-items: center;
      justify-content: center;
      gap: .7rem;
    }
    .cover-content{
      margin-top: 3rem;
      /* height: 100%; */
    }
    .language-card{
      min-height: auto;
      max-height: 500px;
    }
    .feature-container{
      grid-template-columns: repeat(1,1fr);
      margin: 0rem 1rem;
      
  
    }
    .form-container{
      border: none;
    }
    .categories-container{
      grid-template-columns: repeat(1,1fr);
  
    }
    /* .head-md{
      font-size: 1rem;
    } */
    .feedback-container{
      width: 100% !important;
    }
    .flexMobileWrap{
      flex-wrap: wrap;
    }
    .block{
      display: block;
    }
    h1{
      font-size: 1.2rem;
    }
    h2{
      font-size: 1.1rem;
    }
    h3{
      font-size: 1rem;
    }
    .gradiant-blue{
      min-height: 37rem;
    }
    .mobileWithNone{
      width: auto;
    }
    .footer{
      padding: .5rem .8rem;
    }
    .head-md{
      font-size: 1.3rem;
      font-weight: 500;
    }
    .head-lg{
      font-size: 1.8rem;
      font-weight: 500;

    }
    .head-xlg{
      font-size: 2rem;
      font-weight: 500;
    }
    .head-sm{
      font-size: 1rem;
    }
    .workWith{
      grid-template-columns: repeat(2,1fr);
      gap: 1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .workWith >div{
      width: 40%;
    }
    .outline-button{
      padding: 0.6rem 1rem;
    }
    .submit-button{
      padding: 0.8rem 2rem;
    }
    .performance-img{
      width:100%;
      /* margin-left: -65px; */
    }
    .comment-cover{
      width: 80%;
      font-size: .7rem;
    }
    .orange-background{
      padding: .4rem;
      text-align: center;
    }
    .fill-background{
      text-align: center;
      width: 30%;
      max-width: none;
    }
    .categories-container{
      grid-template-columns: repeat(1,1fr);
    }
    .blank-background{
      max-width: none;
      width: 30%;
      text-align: center;
      display: flex;
      text-align: center;
      justify-content: center;
      border: 1px solid white;
    }
    .categories-container2{
      grid-template-columns: repeat(1,1fr);
      gap: 1rem;
    }
    .secure-grid{
      grid-template-columns: repeat(1,1fr);
    }
    .orange-hr{
      top:5% !important
    }
    
  }
  @media  screen and (max-width:900px)and (min-width:700px){
    .feature-container{
      grid-template-columns: repeat(2,1fr);
    }
    .language-container{
      grid-template-columns: repeat(2,1fr);
    }
    .workWith{
      grid-template-columns: repeat(3,1fr);
      gap: 1rem;
    }
    .language-card{
      max-height: 450px;
    }
    .performance-img{
      width:100%;
      /* margin-left: -65px; */
    }
    .workWith{
      grid-template-columns: repeat(2,1fr);
      gap: 1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .workWith >div{
      width: 25%;
    }
    .cover-content-title{
      margin-top: .5rem;
    }
    .head-md{
      font-size: 1.3rem;
      font-weight: 500;
    }
    .head-lg{
      font-size: 1.8rem;
      font-weight: 700;

    }
    .head-xlg{
      font-size: 2rem;
      font-weight: 700;
    }
    .head-sm{
      font-size: 1rem;
    }
  }
  @media only screen and (max-width:700px) {
    .req-button {
      padding: 0.5rem 1rem;
      min-width: max-content;
  
    }
    
  }

/* --------------------tablet view ---------------------- */

@media only screen and (max-width:1050px) {
  .language-container{
    grid-template-columns: repeat(3,1fr);
  }

  
}