#carousel-container2 {
    width: 80%;
    margin: auto;
    text-align: center;
    min-height: 342px;

    position: relative; background-color: #5B5B5B;
  }
   
  .carousel-slide{
   
    padding: 1rem;
  }
  .carousel-slide img {
    width: 100%;
    border-radius: 8px;
    transition: transform 0.5s ease;
  }
   
  .carousel-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .arrow-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    margin: 0 10px;
  }
  .arrow-btn-left {
    left: -6%;
    bottom: 60%;
    background: none;
    color: white;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    font-size: 2rem;
    font-family: "Bebas Neue", sans-serif;

    font-weight:500;
    margin: 0 10px;
    position: absolute;
  }
  .arrow-btn-right {
    right: -6%;
    bottom: 40%;
    color: white;
    background: none;
    border: none;
    font-size: 2rem;
    font-family: "Bebas Neue", sans-serif;

    font-weight:500;
    cursor: pointer;
    margin: 0 10px;
    position: absolute;
  }
   
  .dots {
    display: flex;
    align-items: center;
    justify-content: center;
  }
   
  .dot {
    width: 12px;
    height: 12px;
    margin: 0 5px;
    background-color: rgb(194, 194, 194);
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
   
  .dot.active {
    background-color: rgb(255, 255, 255);
  }
  
  
  
  .slide-container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2rem;
  }

  .player-wrapper {
    position: relative;
    width: 100%;
    /* max-width: 800px; */
    margin: auto;
    /* background: #000; */
  }
   
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
   
  .play-icon {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    color: white;
    /* background: rgba(0, 0, 0, 0.5); */
    border-radius: 50%;
    padding: 20px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
   
  .play-icon:hover {
    transform: translate(-50%, -50%) scale(1.2);
  }
  /* Slide-in animations */
/* .slide-in-right {
  transform: translateX(100%);
  opacity: 0;
  animation:  0.5s forwards;
}
 
.slide-in-left {
  transform: translateX(-100%);
  opacity: 0;
  animation: slide-in-from-left 0.5s forwards;
} */
.slide-in-right {
	-webkit-animation: slide-in-right 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-in-right 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
.slide-in-left {
	-webkit-animation: slide-in-left 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-in-left 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
/* Keyframe animations */
@keyframes slide-in-left {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

@media only screen and (max-width:700px) {
  .slide-container{
    grid-template-columns: repeat(1,1fr);
  }
  #carousel-container2{
    width: 90%;
  }
  .carousel-slide{
    padding: 0;
  }
}
