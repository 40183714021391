.carousel-container {
  /* width: 80%; */
  margin: auto;
  text-align: center;
  position: relative;
}
 
.carousel-slide img {
  width: 100%;
  border-radius: 8px;
  transition: transform 0.5s ease;
}
 
.carousel-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
 
.arrow-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  margin: 0 10px;
}
 
.dots {
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.dot {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  background-color: rgb(194, 194, 194);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
 
.dot.active {
  background-color: rgb(255, 255, 255);
}



.slide-container{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 2rem;
}